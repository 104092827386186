import Helmet from "react-helmet"
import React from "react"
import ExtendedHomepage from "src/components/extendedHomepage"
import HomepageLayout from "src/layouts/homepage"
import NonSearchDisclosure from "src/components/nonsearchdisclosure"
import CtaDisclosure from "@tightrope/ctadisclosure"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const homepageData = import("./homepage.json");

export default function HomePage() {
  let disclosure = null;
  if(isChrome()){
    module = <ExtendedHomepage data={homepageData}><NonSearchDisclosure data={homepageData}></NonSearchDisclosure></ExtendedHomepage>;
  }
  else{
    module = <ExtendedHomepage data={homepageData}><CtaDisclosure data={homepageData}></CtaDisclosure></ExtendedHomepage>;
  }
  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">{`


          #ctadisclosurenewtab-module--ctadisclaimer, #ctadisclosure-module--ctadisclaimer {
            font-weight: 300;
            color: #f0f0f0;
          }

          #ctadisclosurenewtab-module--ctadisclaimer p, #ctadisclosure-module--ctadisclaimer p {
            color: #f0f0f0;
          }
        `}</style>

      <title>PDF Editor by PDF.live</title></Helmet>
      {module}
    </HomepageLayout>
  )
}
